



































import { Component, Vue } from 'vue-property-decorator'
import { getCampaignLinks } from '@/utils/personalizations'
import { getFooterById } from '@/api/consoleApi/contents/footers'

@Component({
    name: 'VsBoosterClickedModal',
})
export default class extends Vue {
    private selectedLink = ''
    private links: any[] = []
    private footerHtml = ''
    private dialog = false
    private loading = false
    resolve: any = null
    reject: any = null
    $refs: any

    get mappedLinks () {
        const mappedLinks = this.links.map((link) => {
            return {
                value: link,
                label: link,
            }
        })

        mappedLinks.unshift({
            value: '',
            label: this.$t('campaigns.createCampaignBooster.allLink'),
        })

        return mappedLinks
    }

    public async save () {
        this.loading = true
        this.resolve(this.selectedLink)
    }

    public async open (message: any): Promise<any> {
        this.loading = false
        this.dialog = true
        this.selectedLink = ''
        await this.getFooter(message.footer_id)
        this.links = getCampaignLinks(message.data, this.footerHtml)
        return new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
    }

    public cancel (): void {
        this.reject(false)
    }

    public close () {
        this.dialog = false
    }

    private async getFooter (footerId: number) {
        if (footerId) {
            try {
                const resp = await getFooterById(footerId)
                this.footerHtml = resp.data.data.html
            } catch (e) {
                console.log(e)
                this.footerHtml = ''
            }
        } else {
            this.footerHtml = ''
        }
    }
}
