

































































import { Component, Vue } from 'vue-property-decorator'
import VsActionCard from '@/components/VsActionCard/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'
import { createBoosterCampaign, getCampaign } from '@/api/consoleApi/campaigns'
import { ICampaign } from '@/api/consoleApi/dto/campaigns.dto'
import VsBoosterClickedModal from '@/modules/campaigns/components/VsBoosterClickedModal/Index.vue'
import VsBoosterNewContactsModal from '@/modules/campaigns/components/VsBoosterNewContactsModal/Index.vue'
import { UserModule } from '@/store/modules/user'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsCampaignBooster',
    components: {
        VsActionCard,
        VsContainer,
        VsLoader,
        VsFullTopBarLayout,
        VsBoosterClickedModal,
        VsBoosterNewContactsModal,
    },
})
export default class extends Vue {
    private loading = false
    private isBooster = false
    private campaign: ICampaign | null = null
    private links: any[] = []
    private boosterData = {
        target: '',
        link: '',
        from: '',
    }

    private boosterTypes = [
        {
            name: this.$t('campaigns.createCampaignBooster.notOpened'),
            subtitle: this.$t('campaigns.createCampaignBooster.notOpenedSubtitle'),
            type: 'notOpened',
            hover: false,
            src: require('@/assets/img/campaigns/non-aperto.jpg'),
            srcHover: require('@/assets/img/campaigns/non-aperto-hover.jpg'),
        },
        {
            name: this.$t('campaigns.createCampaignBooster.opened'),
            subtitle: this.$t('campaigns.createCampaignBooster.openedSubtitle'),
            type: 'opened',
            hover: false,
            src: require('@/assets/img/campaigns/aperto.jpg'),
            srcHover: require('@/assets/img/campaigns/aperto-hover.jpg'),
        },
        {
            name: this.$t('campaigns.createCampaignBooster.clicked'),
            subtitle: this.$t('campaigns.createCampaignBooster.clickedSubtitle'),
            type: 'clicked',
            hover: false,
            src: require('@/assets/img/campaigns/cliccato.jpg'),
            srcHover: require('@/assets/img/campaigns/cliccato-hover.jpg'),
        },
        {
            name: this.$t('campaigns.createCampaignBooster.newContacts'),
            subtitle: this.$t('campaigns.createCampaignBooster.newContactsSubtitle'),
            type: 'newContacts',
            hover: false,
            src: require('@/assets/img/campaigns/nuovi-iscritti.jpg'),
            srcHover: require('@/assets/img/campaigns/nuovi-iscritti-hover.jpg'),
        },
    ]

    $refs: any

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get campaignId () {
        return this.$route.params.campaignId || ''
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get isEmail () {
        return (this.campaign?.content_type === 'email')
    }

    get canBooster () {
        return this.userRules.booster
    }

    get canCreateBooster () {
        return this.isEmail && this.campaign?.status === 'Sent' && this.campaign?.type !== 'Booster'
    }

    async beforeMount () {
        this.loading = true
        await this.getCampaign()
        this.checkIfBooster()
        this.loading = false
    }

    private async getCampaign () {
        this.loading = true
        try {
            const resp = await getCampaign(this.campaignId)
            this.campaign = resp.data.data
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    private checkIfBooster () {
        if (!this.canBooster || !this.canCreateBooster) {
            this.$router.push({ name: 'campaignsIndex' })
            return
        }
        this.isBooster = true
    }

    private async setBoosterData (target: string) {
        switch (target) {
            case 'clicked':
                try {
                    this.boosterData.link = await this.$refs.boosterClickedModal.open(this.campaign?.message)
                    this.boosterData.target = target
                } catch (e) {
                    this.$refs.boosterClickedModal.close()
                    this.boosterData.link = ''
                    this.boosterData.target = ''
                }
                break
            case 'newContacts':
                try {
                    this.boosterData.from = await this.$refs.boosterNewContactsModal.open(this.campaign?.sent_finish)
                    this.boosterData.target = target
                } catch (e) {
                    this.$refs.boosterNewContactsModal.close()
                    this.boosterData.from = ''
                    this.boosterData.target = ''
                }
                break
            case 'notOpened':
            case 'opened':
                this.boosterData.target = target
                this.boosterData.link = ''
                this.boosterData.from = ''
                break
            default:
                break
        }
        if (!this.boosterData.target) return
        await this.createBooster()
    }

    private async createBooster () {
        this.loading = true
        try {
            const resp = await createBoosterCampaign(this.campaignId, this.boosterData)
            const campaignId = resp.data
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.createCampaignBooster.boosterCreated'),
                aspect: VsToastAspectEnum.success,
            })
            this.$router.push({
                name: 'campaignEdit',
                params: {
                    campaignId,
                },
            })
        } catch (e) {
            const errorCode = get(e, 'response.status', '')
            let message = ''
            if (errorCode === 406) {
                message = 'Impossibile creare booster di campagne con etichette o profilazioni'
            }
            this.$root.$vsToast({
                timeout: 5000,
                heading: this.$t('campaigns.createCampaignBooster.boosterError'),
                message,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }
}
