










































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'

@Component({
    name: 'VsBoosterClickedModal',
})
export default class extends Vue {
    private date = ''
    private sentFinishDate = ''
    private dialog = false
    private loading = false
    resolve: any = null
    reject: any = null
    $refs: any

    get minDate () {
        return moment(this.sentFinishDate).format()
    }

    public async save () {
        this.loading = true
        this.resolve(moment(this.date).unix())
    }

    public async open (date: any): Promise<any> {
        this.loading = false
        this.dialog = true
        this.sentFinishDate = date
        this.date = date
        return new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
    }

    public cancel (): void {
        this.reject(false)
    }

    public close () {
        this.dialog = false
    }
}
